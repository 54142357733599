import React, { useState, useContext, useEffect } from 'react'
import { GlobalDispatchContext } from '../../context/GlobalContextProvider'
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Table,
  Collapse,
  Modal
} from 'react-bootstrap'
import { Link, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { replace } from 'lodash'
import right_arrow from '../../assets/icons/link_arrow_icon.svg'
import apple_trademark from '../../assets/images/apple_trademark.svg'
import cocacola_trademark from '../../assets/images/cocacola_trademark.svg'
import nike_trademark from '../../assets/images/nike_trademark.svg'
import checkmark_icon from '../../assets/icons/checkmark_icon.svg'
import x_icon from '../../assets/icons/x_icon.svg'
import expand_icon from '../../assets/icons/expand_icon.svg'
import homepage_quote_icon from '../../assets/icons/homepage_quote_icon.svg'
import heirlume_logo from '../../assets/images/logos/heirlume.png'
import haloo_logo from '../../assets/images/logos/haloo.png'
import Cookies from 'js-cookie'

var trademark_images = [apple_trademark, cocacola_trademark, nike_trademark]

const MainPage = ({ homeData, locale }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const [openAccordion1, setOpenAccordion1] = useState(false)
  const [showModal, setShowModal] = useState(true)

  const { link, placeholder, btn } = JSON.parse(
    homeData?.firstCta.internal.content
  )
  const handleTrademark = (e) => {
    dispatch({ type: 'SET_TRADEMARK', payload: { trademark: e.target.value } })
  }

  const checkoutLink = 'checkout'

  const getAltText = (assetNAme) => {
    var temp = assetNAme.split('.')[0]
    if (temp.indexOf('_') == -1) {
      return temp
    } else {
      try {
        return replace(temp, '_', ' ')
      } catch (error) {
        return temp
      }
    }
  }

  function handleModal() {
    Cookies.set('showModal', false)
    setShowModal(false)
  }

  useEffect(() => {
    const isShowModal = Cookies.get('showModal')
    if (!isShowModal || isShowModal === undefined) {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }, [])

  return (
    <div className='homepage'>
      {/* header section */}
      <Container className='header-image px-0' fluid>
        <Container className='px-md-0'>
          <Row className='justify-content-center justify-content-md-start pt-5'>
            <Col className='col-11 my-5 py-5 text-white' md='7'>
              <h1 className='mt-md-4'>{homeData?.title}</h1>
              <p className='pt-2'>{homeData?.bodyText0}</p>
              <div className='input-container'>
                <button
                  onClick={() => navigate(`${checkoutLink}`)}
                  className='button button-primary button-sm'
                >
                  Get Started
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Heirlume helped section */}
      <Container className='bg-beige with-shape px-0 py-4 py-md-5' fluid>
        <Container className='pb-3'>
          <Row className='justify-content-center'>
            <Col className='col-11 text-center px-0' md='10'>
              <p className='h3 bottom-border-after'>{homeData?.headingText0}</p>
              <Link
                className='d-inline-flex align-items-end'
                to={JSON.parse(homeData?.buttonText2.internal.content).link}
              >
                {JSON.parse(homeData?.buttonText2.internal.content).text}
                <Image
                  className='ml-2'
                  src={right_arrow}
                  width='20'
                  height='20'
                  aria-hidden='true'
                ></Image>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* how we work section */}
      <Container className='pb-5 py-md-5'>
        <Row className='justify-content-center'>
          <Col
            className='d-flex justify-content-center pt-4 pb-4 pb-md-5 mb-2'
            md='12'
          >
            <p className='h2'>{homeData?.headingText1}</p>
          </Col>
          {homeData?.workCardsTitle.map((title, index) => {
            return (
              <Col className='col-11 px-md-2' key={index} md='3'>
                <div className='card grid-cards border-0 rounded-0'>
                  <div className='card-header bg-transparent border-0'>
                    <Image
                      src={homeData?.workCardsIcon[index].file.url}
                      width='70'
                      height='102'
                      fluid
                      aria-hidden='true'
                    ></Image>
                  </div>
                  <div className='card-body px-0 border-bottom border-top'>
                    <p className='h4 mb-3 mb-md-0'>{title}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: JSON.parse(
                          homeData?.workCardsBody[index].internal.content
                        ).body
                      }}
                    ></p>
                  </div>
                </div>
              </Col>
            )
          })}
          <Col className='col-11 mt-3 mt-md-4' md='4'>
            <Button
              className='px-3 py-3 w-100'
              as={Link}
              variant='warning'
              to={JSON.parse(homeData?.buttonText3.internal.content).link}
            >
              {JSON.parse(homeData?.buttonText3.internal.content).text}
            </Button>
          </Col>
        </Row>
      </Container>
      {/* trademark difference section */}
      <Container className='position-relative px-0' fluid>
        <Container className='py-3 mb-5'>
          <Row className='justify-content-center row-trademark'>
            <Col className='text-center mb-4 pb-2' md='12'>
              <p className='h6'>{homeData?.headingText2}</p>
              <p className='h3'>{homeData?.headingText3}</p>
            </Col>
            {homeData?.trademarkDifferences.map((trademark, index) => {
              return (
                <Col
                  className={
                    index == 2
                      ? 'trademarks col-11'
                      : 'd-none d-md-block trademarks col-11'
                  }
                  key={index}
                  md='3'
                >
                  <Image
                    src={trademark_images[index]}
                    height='205'
                    width='100%'
                    aria-hidden='true'
                  ></Image>
                  {JSON.parse(trademark.internal.content).title ? (
                    <p className='mb-2'>
                      {JSON.parse(trademark.internal.content).title}
                    </p>
                  ) : null}
                  {JSON.parse(trademark.internal.content).body ? (
                    <p className='small'>
                      {JSON.parse(trademark.internal.content).body}
                    </p>
                  ) : null}
                  {JSON.parse(trademark.internal.content).title0 ? (
                    <p className='mb-2'>
                      {JSON.parse(trademark.internal.content).title0}
                    </p>
                  ) : null}
                  {JSON.parse(trademark.internal.content).body0 ? (
                    <p className='small'>
                      {JSON.parse(trademark.internal.content).body0}
                    </p>
                  ) : null}
                  {JSON.parse(trademark.internal.content).title1 ? (
                    <p className='mb-2 mt-4'>
                      {JSON.parse(trademark.internal.content).title1}
                    </p>
                  ) : null}
                  {JSON.parse(trademark.internal.content).body1 ? (
                    <p className='small'>
                      {JSON.parse(trademark.internal.content).body1}
                    </p>
                  ) : null}
                  {JSON.parse(trademark.internal.content).title2 ? (
                    <p className='mb-2 mt-4'>
                      {JSON.parse(trademark.internal.content).title2}
                    </p>
                  ) : null}
                  {JSON.parse(trademark.internal.content).body2 ? (
                    <p className='small'>
                      {JSON.parse(trademark.internal.content).body2}
                    </p>
                  ) : null}
                </Col>
              )
            })}
          </Row>
          {/* table */}
          <Row className='justify-content-center mt-5 pt-3 row-table'>
            <Col className='text-center mb-4' md='12'>
              <p className='h3'>
                {
                  JSON.parse(homeData?.heirlumeAdvantageTable.internal.content)
                    .title
                }
              </p>
            </Col>
            <Col md='8'>
              <Table responsive bordered hover>
                <colgroup>
                  <col span='1' style={{ width: '30%' }} />
                  <col span='1' style={{ width: '15%' }} />
                  <col span='1' style={{ width: '15%' }} />
                  <col span='1' style={{ width: '15%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    {JSON.parse(
                      homeData?.heirlumeAdvantageTable.internal.content
                    ).cols.map((col, index) => {
                      return (
                        <th
                          key={index}
                          className={index != 2 ? 'font-weight-normal' : ''}
                        >
                          {col}
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(
                    homeData?.heirlumeAdvantageTable.internal.content
                  ).rows.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {index ==
                          JSON.parse(
                            homeData?.heirlumeAdvantageTable.internal.content
                          ).rows.length -
                            1 ? (
                            <div className='d-flex flex-column align-items-start'>
                              {row.title}
                              <Image
                                className='mt-2'
                                src={homeData.tableLogo.file.url}
                                height='13'
                                width='114'
                                fluid
                                aria-hidden='true'
                              ></Image>
                            </div>
                          ) : (
                            row.title
                          )}
                        </td>
                        <td className='text-center'>
                          {typeof row.values[0] == 'boolean' ? (
                            row.values[0] == true ? (
                              <Image
                                src={checkmark_icon}
                                height='15'
                                width='20'
                                aria-label='included'
                              ></Image>
                            ) : (
                              <Image
                                src={x_icon}
                                width='15'
                                height='15'
                                aria-label='not included'
                              ></Image>
                            )
                          ) : (
                            row.values[0]
                          )}
                        </td>
                        <td className='text-center'>
                          {typeof row.values[1] == 'boolean' ? (
                            row.values[1] == true ? (
                              <Image
                                src={checkmark_icon}
                                height='15'
                                width='20'
                                aria-label='included'
                              ></Image>
                            ) : (
                              <Image
                                src={x_icon}
                                width='15'
                                height='15'
                                aria-label='not included'
                              ></Image>
                            )
                          ) : (
                            row.values[1]
                          )}
                        </td>
                        <td className='text-center'>
                          {typeof row.values[2] == 'boolean' ? (
                            row.values[2] == true ? (
                              <Image
                                src={checkmark_icon}
                                height='15'
                                width='20'
                                aria-label='included'
                              ></Image>
                            ) : (
                              <Image
                                src={x_icon}
                                width='15'
                                height='15'
                                aria-label='not included'
                              ></Image>
                            )
                          ) : (
                            <strong>{row.values[2]}</strong>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* BNPL section */}
      <Container className='bnpl-container position-relative px-0' fluid>
        <Container className='py-3 py-md-5'>
          <Row className='align-items-center justify-content-md-between text-white py-4'>
            <Col className='col-11 offset-1 offset-md-0' md='6'>
              <p className='h3 text-white'>
                {JSON.parse(homeData?.clearbancCardText.internal.content).title}
              </p>
              <p>
                {
                  JSON.parse(homeData?.clearbancCardText.internal.content)
                    .subtitle
                }
              </p>
              <p
                className='small mt-4'
                dangerouslySetInnerHTML={{
                  __html: JSON.parse(
                    homeData?.clearbancCardText.internal.content
                  ).body
                }}
              ></p>
              <bnpl-modal
                class='clearco-link d-none d-lg-block'
                partnerType='invoice'
              >
                {
                  JSON.parse(homeData?.clearbancCardText.internal.content)
                    .linkText
                }
              </bnpl-modal>
            </Col>
            <Col
              className='col-6 offset-1 offset-md-0 mt-4 mt-md-0'
              xs={9}
              md='5'
            >
              <Image fluid src={homeData.clearcoLogo.file.url}></Image>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* quotes section */}
      <Container className='px-0' fluid>
        <Container className='pb-4 py-md-5'>
          <Row className='align-items-center justify-content-end justify-content-md-between py-5'>
            <Col className='quote-col d-flex justify-content-between' md='5'>
              <Image
                className='mt-2 mr-4'
                src={homepage_quote_icon}
                width='57'
                height='41'
                aria-hidden='true'
              ></Image>
              <div>
                <p>
                  {JSON.parse(homeData?.quotesText[0].internal.content).qoute}
                </p>
                <p className='small mt-3'>
                  {JSON.parse(homeData?.quotesText[0].internal.content).name}
                </p>
                <p className='small-light'>
                  {JSON.parse(homeData?.quotesText[0].internal.content).company}
                </p>
              </div>
            </Col>
            <Col className='col-9 quote-img dwayne mt-5 mt-md-0' md='6'>
              <Img fluid={homeData?.quotesImage[0].fluid}></Img>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-between py-md-5 my-5'>
            <Col
              className='col-10 quote-img harry order-2 order-md-1 mt-5 mt-md-0'
              md='6'
            >
              <Img fluid={homeData?.quotesImage[1].fluid}></Img>
            </Col>
            <Col
              className='quote-col d-flex justify-content-between order-1 order-md-2'
              md='5'
            >
              <Image
                className='mt-2 mr-4'
                src={homepage_quote_icon}
                width='57'
                height='41'
                aria-hidden='true'
              ></Image>
              <div>
                <p>
                  {JSON.parse(homeData?.quotesText[1].internal.content).qoute}
                </p>
                <p className='small mt-3'>
                  {JSON.parse(homeData?.quotesText[1].internal.content).name}
                </p>
                <p className='small-light'>
                  {JSON.parse(homeData?.quotesText[1].internal.content).company}
                </p>
              </div>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-end justify-content-md-between py-5'>
            <Col className='quote-col d-flex justify-content-between' md='5'>
              <Image
                className='mt-2 mr-4'
                src={homepage_quote_icon}
                width='57'
                height='41'
                aria-hidden='true'
              ></Image>
              <div>
                <p>
                  {JSON.parse(homeData?.quotesText[2].internal.content).qoute}
                </p>
                <p className='small mt-3'>
                  {JSON.parse(homeData?.quotesText[2].internal.content).name}
                </p>
                <p className='small-light'>
                  {JSON.parse(homeData?.quotesText[2].internal.content).company}
                </p>
              </div>
            </Col>
            <Col className='col-10 quote-img ann mt-5 mt-md-0' md='6'>
              <Img fluid={homeData?.quotesImage[2].fluid}></Img>
            </Col>
          </Row>
          {/* partner network */}
          <Row className='mt-3 mt-md-5 mb-4 justify-content-center'>
            <Col className='col-4' md='2'>
              <hr className='grey-hr' />
            </Col>
          </Row>
          <Row className='justify-content-center align-items-center'>
            <Col className='text-center' md='6'>
              <p className='h4'>{homeData?.headingText4}</p>
            </Col>
          </Row>
          <Row className='mt-3 justify-content-center align-items-center'>
            {homeData?.partnersNetworkLogo.map((logo, index) => {
              return index < 2 ? (
                <Col className='col-5' md='3' key={index}>
                  <Image
                    className='mt-2 mr-4'
                    src={logo.file.url}
                    height='41'
                    width='255'
                    fluid
                    alt={getAltText(logo.file.fileName)}
                  ></Image>
                </Col>
              ) : (
                <React.Fragment key={index}>
                  {index == 2 ? (
                    <Col className='w-100 mt-3' md='12' />
                  ) : (
                    <Col className='col-auto text-center' md='auto'>
                      <p className='h3 text-muted' aria-hidden='true'>
                        +
                      </p>
                    </Col>
                  )}
                  <Col className='col-4' md='2' key={index}>
                    <Image
                      className='mt-2 mr-4'
                      src={logo.file.url}
                      height='41'
                      width='255'
                      fluid
                      alt={getAltText(logo.file.fileName)}
                    ></Image>
                  </Col>
                </React.Fragment>
              )
            })}
          </Row>
        </Container>
      </Container>
      {/* Get started section */}
      <Container className='getstarted-container position-relative px-0' fluid>
        <Container className='py-3 py-md-5'>
          <Row className='align-items-center justify-content-center justify-content-md-between py-4'>
            <Col className='col-11' md='6'>
              <p className='h4'>
                {JSON.parse(homeData?.registerCardText.internal.content).title}
              </p>
              <p className='small'>
                {JSON.parse(homeData?.registerCardText.internal.content).body}
              </p>
            </Col>
            <Col className='col-11 mt-4 mt-md-0' md='3'>
              <Button
                className='px-5 py-3 w-100 d-inline-flex align-items-center justify-content-start right-arrow'
                as={Link}
                variant='warning'
                to={
                  JSON.parse(homeData?.registerCardText.internal.content).button
                    .link
                }
              >
                {
                  JSON.parse(homeData?.registerCardText.internal.content).button
                    .text
                }
                <svg
                  width='22'
                  height='22'
                  viewBox='0 0 22 22'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11.2124 19.9932L20.2641 10.9932L11.2124 1.99316'
                    stroke='currentColor '
                    strokeWidth='2'
                    strokeLinecap='square'
                  />
                  <path
                    d='M1.80574 11.4377H19.5319'
                    stroke='currentColor '
                    strokeWidth='2'
                    strokeLinecap='square'
                  />
                </svg>
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* FAQ section */}
      <Container
        className='bg-beige faq-shapes position-relative pb-5 pt-4 py-md-5 px-0'
        fluid
      >
        <Container className='py-md-5'>
          <Row>
            <Col className='col-5' md='3'>
              <p className='h1'>{homeData?.headingText5}</p>
            </Col>
            <Col
              className='col-11 offset-1 offset-md-0 faq-col mt-4 mt-md-0'
              md={{ span: 6, offset: 2 }}
            >
              <div className='order-1'>
                {homeData?.faqText.slice(0, 4).map((faq, index) => {
                  return (
                    <div key={index}>
                      <p className='mb-2'>
                        {JSON.parse(faq.internal.content).question}
                      </p>
                      <p className='small-text mb-4'>
                        {JSON.parse(faq.internal.content).answer}
                      </p>
                    </div>
                  )
                })}
              </div>
              <Button
                onClick={() => setOpenAccordion1(!openAccordion1)}
                aria-controls='collapse-0'
                aria-expanded={openAccordion1}
                block
                variant='transparent'
                className='order-3 px-0'
              >
                <span>See more common questions</span>
                <Image
                  src={expand_icon}
                  width='20'
                  height='20'
                  fluid
                  aria-hidden='true'
                ></Image>
              </Button>
              {/* expandable questions */}
              <Collapse className='order-2' in={openAccordion1}>
                <div id='collapse-0'>
                  {homeData?.faqText.slice(4, 10).map((faq, index) => {
                    return (
                      <div key={index}>
                        <p className='mb-2'>
                          {JSON.parse(faq.internal.content).question}
                        </p>
                        <p className='small-text mb-4'>
                          {JSON.parse(faq.internal.content).answer}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </Collapse>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* search tool section */}
      <Container className='search-tool position-relative px-0 ' fluid>
        <Container className=''>
          <Row className='align-items-center justify-content-center'>
            <Col className='image-left-float px-0' md='5'>
              <Img fluid={homeData?.searchCardImage.fluid} />
            </Col>
            <Col
              className='content-big-screen col-11 py-5 my-md-5 text-white px-md-0'
              md={{ span: 6, offset: 6 }}
            >
              <p className='h4'>
                {JSON.parse(homeData?.searchCardText.internal.content).title}
              </p>
              <p className='small'>
                {JSON.parse(homeData?.searchCardText.internal.content).body}
              </p>
              <a
                className='px-4 py-3 mt-3 btn btn-warning'
                href={
                  JSON.parse(homeData?.searchCardText.internal.content).button
                    .link
                }
              >
                {
                  JSON.parse(homeData?.searchCardText.internal.content).button
                    .text
                }
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* featured section */}
      <Container className='py-5 my-md-3'>
        <Row className='justify-content-center align-items-center'>
          <Col className='text-center mb-4' md='12'>
            <p className='h3'>{homeData?.headingText6}</p>
          </Col>
          {homeData?.featuredLogos.map((logo, index) => {
            return (
              <Col
                className={index != 4 ? 'col-4' : 'col-5 px-0 pl-md-3'}
                md='auto'
                key={index}
              >
                <Image
                  src={logo.file.url}
                  fluid
                  width={index != 4 ? '150' : '200'}
                  height='47'
                  alt={getAltText(logo.file.fileName)}
                ></Image>
              </Col>
            )
          })}
        </Row>
        <Row className='justify-content-center my-3 my-md-5'>
          <Col className='col-11' md='4'>
            <hr className='dark-hr' />
          </Col>
        </Row>
        {/* qualified logos */}
        <Row className='justify-content-center align-items-center'>
          <Col className='text-center mb-4' md='12'>
            <p className='h3'>{homeData?.headingText7}</p>
          </Col>
          {homeData?.qualifiedLogos.map((logo, index) => {
            return (
              <Col className='col-4' md='auto' key={index}>
                <Image
                  src={logo.file.url}
                  width={
                    (locale.toLowerCase() == 'en-ca' && index == 2) ||
                    (locale.toLowerCase() == 'en-us' && index == 0)
                      ? '130'
                      : '220'
                  }
                  height={
                    (locale.toLowerCase() == 'en-ca' && index == 2) ||
                    (locale.toLowerCase() == 'en-us' && index == 0)
                      ? '65'
                      : '90'
                  }
                  fluid
                  alt={getAltText(logo.file.fileName)}
                ></Image>
              </Col>
            )
          })}
        </Row>
      </Container>
      {/* why choose section */}
      <Container className='bg-aqua py-5 px-0' fluid>
        <Container className='pb-md-5'>
          <Row className='justify-content-center'>
            <Col className='col-11 text-center' md='6'>
              <p className='h3'>{homeData?.headingText8}</p>
            </Col>
            <Col className='col-11 text-center' md='8'>
              <p className='h4'>{homeData?.headingText9}</p>
            </Col>
          </Row>
          {/* cards */}
          <Row className='mt-3 mt-lg-4 why-us-cards justify-content-center'>
            {homeData?.servicesCardTitle.map((title, index) => {
              return (
                <Col className='col-11 my-2 my-lg-0' lg='4' key={index}>
                  <div className='card border-0 rounded-0 h-100'>
                    <div className='card-header border-0 bg-transparent rounded-0 py-4'>
                      <Image
                        className='d-block mx-auto'
                        src={homeData?.servicesCardImage[index].file.url}
                        width='155'
                        height='235'
                        fluid
                        aria-hidden='true'
                      ></Image>
                    </div>
                    <div className='card-body py-2 h-100'>
                      <p className='h4 mb-3'>{title}</p>
                      <p className='light mb-4'>
                        {homeData?.servicesCardBody[index]}
                      </p>
                      <Button
                        as={Link}
                        to={
                          JSON.parse(
                            homeData?.servicesCardButton[index].internal.content
                          ).link
                        }
                        variant='transparent'
                        className='d-inline-flex border-0 px-0'
                      >
                        <span>
                          {
                            JSON.parse(
                              homeData?.servicesCardButton[index].internal
                                .content
                            ).text
                          }
                        </span>
                        <Image
                          className='ml-3'
                          src={right_arrow}
                          width='20'
                          height='20'
                          aria-hidden='true'
                        ></Image>
                      </Button>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Container>
      {/* protect section */}
      <Container className='protect-container position-relative px-0' fluid>
        <Container className='py-3 py-md-5'>
          <Row className='align-items-center py-4'>
            <Col className='col-11 text-white' md='6'>
              <p className='h4'>
                {JSON.parse(homeData?.protectCardText.internal.content).title}
              </p>
              <p className='small'>
                {JSON.parse(homeData?.protectCardText.internal.content).body}
              </p>
            </Col>
            <Col className='col-11 mt-4 mt-md-0' md={{ span: 3, offset: 2 }}>
              <Button
                className='px-5 py-3 w-100 d-inline-flex align-items-center justify-content-start right-arrow'
                as={Link}
                variant='light'
                to={
                  JSON.parse(homeData?.protectCardText.internal.content).button
                    .link
                }
              >
                {
                  JSON.parse(homeData?.protectCardText.internal.content).button
                    .text
                }
                <svg
                  width='22'
                  height='22'
                  viewBox='0 0 22 22'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11.2124 19.9932L20.2641 10.9932L11.2124 1.99316'
                    stroke='currentColor '
                    strokeWidth='2'
                    strokeLinecap='square'
                  />
                  <path
                    d='M1.80574 11.4377H19.5319'
                    stroke='currentColor '
                    strokeWidth='2'
                    strokeLinecap='square'
                  />
                </svg>
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* <Modal
        className='trademark-home-modal'
        show={showModal}
        // onHide={stepState.handleModalClose}
        centered
        size='lg'
      >
        <Modal.Body>
          <Row className='mt-4 pt-3 pb-3 mb-5 mx-4 align-items-center grid-divider'>
            <Col md={6} className='text-center'>
              <img
                src={heirlume_logo}
                className='py-3 my-1'
                alt='Heirlume Logo'
                fluid='true'
              />
              <p className='title'>Get Expert Help</p>
              <p className='description mb-3 pb-4'>
                All the accuracy of our AI technology, plus the help of a
                trademark expert to help you along the way.
              </p>
              <div className=''>
                <Button
                  className='px-4 py-3 button button-secondary button-sm heirlume-btn'
                  onClick={() => handleModal()}
                >
                  Continue to Heirlume
                </Button>
              </div>
            </Col>
            <Col md={6} className='text-center mt-md-0 mt-5'>
              <img
                src={haloo_logo}
                alt='Haloo Logo'
                className='mb-1 mt-md-0 mt-4 pt-md-0 pt-3'
                fluid='true'
              />
              <p className='title'>Do It Yourself</p>
              <p className='description pb-3 mb-1'>
                You’ll get your complete trademark search results and a
                perfectly-written application that’s ready to file, fast.
              </p>
              <div className='mt-2'>
                <a
                  className='px-4 py-3 btn button button-primary button-sm haloo-btn'
                  href={`https://haloo.co/`}
                >
                  Trademark Now with Haloo
                </a>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal> */}
    </div>
  )
}

export default MainPage
